
@keyframes break {

    0% { transform: translate(-4px); }

    30% { transform: translate(4px); }

    40% { transform: translate(-2px); }

    50% { transform: translate(4px); }

    60% { transform: translate(-4px); }

    70% { transform: translate(2px); }

    80% { transform: translate(-4px); }

    100% { transform: translate(4px); }
}
