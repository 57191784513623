
.jsPaint {
    &__root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: #FFF;
        image-rendering: pixelated;
    }
}
