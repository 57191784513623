:root {
    --viewportHeight: 100vh;
}

html {
    // See https://developer.chrome.com/blog/300ms-tap-delay-gone-away/
    // touch-action: none;
}

html,
body {
    height: var(--viewportHeight, 100vh);
    overscroll-behavior: none;

    // min-height: 100%;
}

a {
    outline: none;
}

a,
a:link,
a:visited,
a:active {
    color: #000;
    text-decoration: none;
}

button {
    background: transparent;
    border: 0;
    margin: 0;
    outline: none;
    font: inherit;
    line-height: inherit;
}

[hidden] {
    display: none !important;
}

h1,
h2 {
    font-size: inherit;
}
