@import '../../styles/animations/break';

.link {
    $root: &;

    &__root {
        --x-1: 20%;
        --w-1: 20%;
        --x-2: 5%;
        --w-2: 35%;
        --x-3: 60%;
        --w-3: 30%;

        position: relative;
    }

    &__underline {
        position: relative;
        pointer-events: none;
        box-shadow: inset 0 -2px #F0F;
        display: inline-block;
        line-height: 19px;

        .app--showFallback & {
            pointer-events: auto;
        }

        &--hidden {
            box-shadow: none;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            height: 2px;
            display: none;
        }

        &::before {
            bottom: 5px;
            left: var(--x-1);
            width: var(--w-1);
            background: #F0F;
        }

        &::after {
            bottom: 0;
            left: 0;
            width: 100%;
            background: #F0F;
        }

        .app--hasActiveHover #{$root}__root:hover {
            box-shadow: none;
        }

        .app--hasActiveHover #{$root}__root:hover > &::before,
        .app--hasActiveHover #{$root}__root:hover > &::after {
            display: block;
            animation: break .5s;
            animation-iteration-count: infinite;
        }

        .app--hasActiveFocus a:focus > & {
            box-shadow: inset 0 -8px #F0F;
        }
    }

    &__linkEffect {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 9px;
        overflow: hidden;
        pointer-events: none;
        line-height: 19px;
        display: none;

        .app--hasActiveHover #{$root}__root:hover > & {
            display: block;
            animation: break .75s;
            animation-iteration-count: infinite;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            height: 2px;
        }

        &::before {
            top: 2px;
            left: var(--x-2);
            width: var(--w-2);
            background: #F0F;
        }

        &::after {
            top: 7px;
            left: var(--x-3);
            width: var(--w-3);
            background: #F0F;
        }
    }
}
