
@keyframes breathe {

    0% { transform: scale(4.5); }

    30% { transform: scale(4.5); }

    40% { transform: scale(5); }

    50% { transform: scale(3); }

    60% { transform: scale(3.5); }

    70% { transform: scale(5); }

    80% { transform: scale(4.5); }

    100% { transform: scale(4.5); }
}
