@import '../../styles/mixins/media-queries';

.content {
    &__root {
        position: relative;
        padding: 48px 0;
        margin: 0 0 auto;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 18px;
        line-height: 1.25;
        transition: opacity linear 250ms;
        opacity: 1;

        .app--hasActiveRuler & {
            opacity: 0;
            pointer-events: none;
        }

        .app--showScreenshot & {
            padding: 32px 0;
            margin: 0;
        }
    }

    &__videoFigure {
        position: relative;
        width: 100%;
        margin: 16px 0;
    }

    &__videoDescription {
        margin: 0;
        padding-top: 16px;
        color: #000;
        font-size: 12px;
    }

    &__videoPlaceholder {
        position: relative;
        background: rgba(0, 0, 0, .0625);
        border-radius: 2px;
        overflow: hidden;
        display: block;
        margin: 0;
        padding-bottom: 42.5%;
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    &__title {
        margin: 0;
    }

    &__name {
        display: block;
        font-family: Cardo, serif;
        line-height: 1;
        font-size: 32px;

        @include above(xs) {
            font-size: 64px;
        }
    }

    &__role,
    &__p,
    &__links,
    &__hiringLinks {
        margin: 0;
        padding-top: 16px;
    }
}
